var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section--check grey lighten-5"
  }, [_c('div', {
    staticClass: "main-section--check__left"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "xl": "6"
    }
  }, [_c('tit-wrap-primary', {
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("C")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("HECK LIST")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("성범죄 로펌 선택 전 "), _c('span', {
          staticClass: "font-primary"
        }, [_vm._v("11")]), _vm._v("가지 체크리스트")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "main-section--check__right"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "xl": "6",
      "offset-xl": "6"
    }
  }, [_c('div', {
    staticClass: "main-section--check__right__inner"
  }, [_c('card-primary', {
    attrs: {
      "color": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-60px"
  }, [_c('div', {
    staticClass: "pb-30px pb-md-48px"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-semibold line-height-1 primary--text mb-12px mb-md-24px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" Question ")]), _c('u-tit-default', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 어떤 로펌을 선택할 것인가가? ")])], 1), _c('v-row', {
    staticClass: "row--xs"
  }, _vm._l(_vm.info, function (detail, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-semibold line-height-1 w-40px",
      attrs: {
        "data-aos": "fade-right"
      }
    }, [index < 9 ? _c('span', [_vm._v("0")]) : _vm._e(), _vm._v(_vm._s(index + 1) + " ")])], 1), _c('v-col', [_c('u-txt-default', {
      staticClass: "line-height-15",
      attrs: {
        "data-aos": "fade-left"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(detail)
      }
    })])], 1)], 1)], 1);
  }), 1)], 1)])], 1)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }